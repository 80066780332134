import React from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
    return (
        <nav style={{ display: "flex", justifyContent: "center", fontSize:"18px" }}>
          <ul style={{ display: "flex", listStyleType: "none", margin: "0", padding: "0" }}>
            <li style={{ marginRight: "20px" }}>
              <Link to="/">首页</Link>
            </li>
            <li style={{ marginRight: "20px" }}>
              <Link to="/guideline">党的纲领</Link>
            </li>
            <li style={{ marginRight: "20px" }}>
              <Link to="/membership">成为党员</Link>
            </li>
            <li style={{ marginRight: "20px" }}>
              <Link to="/goals">战略目标</Link>
            </li>
            <li style={{ marginRight: "20px" }}>
              <Link to="/prosperity">政论文章</Link>
            </li>
            <li style={{ marginRight: "20px" }}>
              <Link to="/tactics">行动计划</Link>
            </li>
            <li style={{ marginRight: "20px" }}>
              <Link to="/speakings">演讲范例</Link>
            </li>
          </ul>
        </nav>
      );
    };


export default Navbar;
