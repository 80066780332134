import { BrowserRouter as Router, Routes, Route} from 'react-router-dom'
//import logo from './logo.svg';
import './App.css';
import Goals from './Pages/Goals';
import Guideline from './Pages/Guideline';
import HomePage from './Pages/Home'
import Membership from './Pages/Membership';
import Speakings from './Pages/Speakings';
import Tactics from './Pages/Tactics';
import Prosperity from './Pages/Prosperity';

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route exact path="" element={<HomePage />} />
          <Route path="home" element={<HomePage />} />
          <Route path="guideline" element={<Guideline />} />
          <Route path="membership" element={<Membership />} />
          <Route path="goals" element={<Goals />} />
          <Route path="tactics" element={<Tactics />} />
          <Route path="speakings" element={<Speakings />} />
          <Route path="prosperity" element={<Prosperity />} />
        </Routes>
      </Router>
    </div>
    
  );
   
}

export default App;
