import { Helmet } from 'react-helmet';

import {RegDiv} from "../../Components";
import Doctrine from "../../Components/Doctrine";
import Tittle from "../../Components/Tittle";
import Videolist from "../../Components/VideoList";
import Navbar from "../../Navigation";

function Prosperity(){
  const pdfUrl = 'prosper.pdf';

  // Function to open PDF in a new tab
  const openPdf = () => {
    window.open(pdfUrl, '_blank');
  };

    return (
      <div>
      <Helmet>
        <title>通向繁荣之路</title>
        <meta name="通向繁荣之路" content=" 通向繁荣之路." />
      </Helmet>
      <RegDiv padding="6%" width="90vw">
        <RegDiv flexdirection="row" >
          <RegDiv width="450px"  >
            <Tittle/>
          </RegDiv>
          <RegDiv  >
            <RegDiv height="2vw" ></RegDiv>
            <Navbar/>
          </RegDiv>
        </RegDiv>

        <RegDiv  flexdirection="row">        
          <RegDiv padding="2%" width="450px" mobiledisplay="none">
            <RegDiv>
              <Doctrine/>
            </RegDiv>        
            <RegDiv>
              <Videolist/>
            </RegDiv>
          </RegDiv>
          <RegDiv padding="2%" width="600px">  
            <h1>《通向繁荣之路》</h1>       
            <p> 由冯泽所著文章《通向繁荣之路》探讨了世界范围内富裕民主国家的共同特点，贫困民主国家的共同特点，
              以及民主转型成功和失败国家的经验教训，提出了一条能够让国家走上繁荣富裕的道路。下面是文章的引言部分。如要通读全文，请点击“观看全文”按钮阅览。注意：文章可以自由引用和传播，但是需要引用传播时注明文章出处。
            </p>
            <div>
            <button onClick={openPdf}>观看全文</button>
          </div>
          <RegDiv>
              <img src="contents.png" alt="Content list"   />  
              <img src="prolog.png" alt="pro log"   /> 
          </RegDiv>
           
          </RegDiv>
           
        </RegDiv>
        <RegDiv  flexdirection="row">  
          
        </RegDiv>
      
      </RegDiv>
      </div>
  ); 
    
}

export default Prosperity;